<template>
	<div>
		<headerbox :logo="logo" :namNum="navindex"></headerbox>
		
		<div class="back">
			<img src="../../assets/eventtheme/back2.png" alt="">
		</div>
		
		<div class="notice">
			
			<div class="header">
				<div @click="close" class="titlename">{{date.columnName}}></div>
				<div class="current">正文</div>
			</div>
		
			<div class="title" v-html="date.title"></div>
			
			<div class="horizontalline"></div>
		
			<div class="articledetails">
				<div class="name1"><span>来源：</span>{{date.source}}</div>
				<div class="name2"><span>时间：</span>{{date.publishTime}}</div>
			</div>
			<div v-html="date.content" class="content"></div>
			<!-- <div v-if="date.imgUrl" style="margin-bottom: 20px">
				<img :src="date.imgUrl" alt="" style="width: 1200px;">
			</div> -->
			<div v-if="date.fileUrlList && date.fileUrlList.length > 0">
				<ul>
					<li v-for="(item,index) in date.fileUrlList" :key="index+'one'" style="margin-bottom: 20px"><a
							:href="item.url" style="color: #2d52a0">
							<img src="/images/fujian.png" alt="" style="width: 20px">
							{{item.name}}
						</a></li>
				</ul>
			</div>
		
			<div class="button">
				<div class="button_name" @click="getupper">【 {{ upper }} 】</div>
				<div class="button_name" @click="getbelow">【 {{ below }} 】</div>
				<div class="button_name" @click="close">【 关闭 】</div>
			</div>
		
			<div class="left_window">
				<h2 style="padding: 5px 10px;">相关信息</h2>
				<ul style="font-size: 14px; line-height: 28px;">
					<li class="spantodate1" v-for="(item,index) in recomList" :key="index" @click="jump(item.id)">
						·{{item.title}}</li>
				</ul>
			</div>
			
		</div>
			<footerbox></footerbox>
	</div>
</template>
<script>
	import Vue from 'vue'
	import Meta from 'vue-meta'
	import headerbox from './component/header.vue'
	import footerbox from './component/footer.vue'
	Vue.use(Meta)
	export default {
		name: 'articledetails',
		components: {
			headerbox,
			footerbox,
		},
		data() {
			return {
				id: '',
				date: {},
				recomList: [],
				upper: '上一篇',
				upperbur: false,
				below: '下一篇',
				belowbur: false,
				logo: '',
				navindex: 0
			}
		},
		created() {
			this.id = this.$route.query.subjectid
			this.logo = this.$route.query.logo
			this.navindex = this.$route.query.navindex
			this.$store.commit("EXIT_STATIONID", this.$route.query.id)
			this.getArticleData(this.id)
		},
		methods: {
			getArticleData(id) {
				let data = {
					id: id,
				}
				this.$api.getArticleData(data).then(res => {
					this.date = res.data.data
					this.recommend(res.data.data.columnId)
					
					document.title = this.date.title.replace(/<br>/g, ' ') + '-北京市体育竞赛管理和国际交流中心'
					this.$meta().refresh()
					
					if (res.data.data.prevArticle == null || res.data.data.prevArticle == undefined || res.data.data.prevArticle == '') {
						this.upper = '结束了',
							this.upperbur = true
					}else{
						this.upper = '上一篇',
						this.upperbur = false
					}
					
					if (res.data.data.nextArticle == null || res.data.data.nextArticle == undefined || res.data.data.nextArticle == '') {
						this.below = '结束了',
							this.belowbur = true
					}else{
						this.below = '下一篇',
						this.belowbur = false
					}
				})
			},
			recommend(id) {
				this.$api.recommend(id).then(res => {
					this.recomList = res.data.data
					
					this.recomList.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/iceskating/details',
					query: {
						subjectid: id,
						logo: this.$route.query.logo,
						navindex: this.$route.query.navindex,
						id: this.$route.query.id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
			close() {
				window.close()
			},
			getupper() {
				if (this.date.prevArticle == null || this.date.prevArticle == undefined || this.date.prevArticle == '')
					return
				this.getArticleData(this.date.prevArticle.id)
				window.scrollTo(0, 0)
			},
			getbelow() {
				if (this.date.nextArticle == null || this.date.nextArticle == undefined || this.date.nextArticle == '')
					return
				this.getArticleData(this.date.nextArticle.id)
				window.scrollTo(0, 0)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.back {
		height: 547px;
		width: 100%;
	
		img {
			width: 100%;
			max-width: 100%;
			height: 100%;
			max-height: 100%;
			object-fit: cover;
		}
	}
	
	.notice {
		width: 1200px;
		margin: 0 auto;
	}

	.header {
		display: flex;
		margin-top: 34px;
		font-size: 18px;
		font-weight: 500;
		color: #333333;
		margin-bottom: 23px;
		cursor: pointer;
	}

	.current {
		color: #0066FF;
	}

	.content {
		width: 1200px;
		margin: 0 auto;
	}

	.horizontalline {
		border: 1px dashed #BFBFBF;
		margin-bottom: 23px;
	}

	.articledetails {
		display: flex;
		width: 1200px;
		margin: 0 auto 36px;
		font-size: 16px;
		justify-content: center;
	}

	.name1 span {
		color: #9A9A9A;
		font-size: 15px;
	}

	.name2 span {
		color: #9A9A9A;
		font-size: 15px;
	}

	.name2 {
		margin-left: 15px;
	}

	.title {
		text-align: center;
		// height: 100px;
		/* line-height: 100px; */
		font-size: 24px;
		color: #343434;
		padding: 10px 0;
	}

	.content {
		margin-bottom: 20px;
	}

	.left_window {
		border: 1px solid #D4D4D4;
		margin-bottom: 20px;
		background: none repeat scroll 0 0 #FFFFFF;
		margin-top: 40px;
	}

	.left_window h2 {
		line-height: 25px;
		font-weight: 800;
		color: #111111;
		font-size: 15px;
		padding: 0px 10px;
		background-color: #F5F5F5;
	}

	.left_window ul {
		font-size: 13px;
		line-height: 25px;
		margin: 10px;
	}

	li {
		list-style: none;
		cursor: pointer;
	}

	.button {
		width: 1200px;
		margin: 40px auto 0;
		display: flex;
		justify-content: flex-end;
	}

	.button_name {
		cursor: pointer;
	}
	
	.titlename:hover{
		color: #0066FF;
	}
</style>